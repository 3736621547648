import React, { useState } from 'react';
import { Input, Button, Form, message, Space } from 'antd';
import { SendOutlined, CheckCircleOutlined, MailOutlined } from '@ant-design/icons';

const EmailCard = ({ color, onSubmit, isEmailSubmitted, setIsEmailSubmitted }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (isEmailSubmitted || !email) {
      return; // Do nothing if already submitted or email is empty
    }

    if (!email) {
      message.error("Please enter an email address.");
      return;
    }

    setLoading(true);
    const success = await onSubmit(email); // Call the parent component's handleSubmit function
    if (success) {
    }
    setLoading(false);
  };

  return (
  
    <Form.Item 
    rules={[
    {
        type: 'email',
        message: 'Please enter a valid email',
    },
    ]}
    style={{ background: '#F6F7F7', padding: '10px', borderRadius: '2px', marginBottom: '2px' ,width:'100%'}}>
      {/* <Space style={{ width: '100%' }}> */}
        <Input
          style={{ width: '100%',alignItems:'center' }}
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          suffix={
            <Button
            style={{ background: color, color: 'white' }}
            onClick={handleSubmit}
            icon={!isEmailSubmitted ? <SendOutlined /> : <CheckCircleOutlined />}
            disabled={isEmailSubmitted}
            loading={loading}
           
          >
            {isEmailSubmitted ? "" : ""}
          </Button>
          }
          prefix={<MailOutlined />}
          required
          disabled={isEmailSubmitted}
     
        />
 
      {/* </Space> */}
    </Form.Item>
  );
};



export default EmailCard;
